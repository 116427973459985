.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}
.react-datepicker {
  font-family: "Overpass";
  background-color: #fff;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
}
.react-datepicker-popper {
  z-index: 15;
}
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}
.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}
.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 6px;
}
.react-datepicker__header {
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  position: relative;
}
.react-datepicker__month {
  padding: 0 12px 12px 12px;
  text-align: center;
}
.react-datepicker__month-container {
  float: left;
}
.react-datepicker__day-names {
  display: flex;
  padding: 0 12px;
  text-transform: uppercase;
  white-space: nowrap;
}
.react-datepicker__week {
  white-space: nowrap;
  display: flex;
}
.react-datepicker__day-name {
  color: #596266;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}
.react-datepicker__day-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 32px;
  text-align: center;
  margin: 0;
}
.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  line-height: 38px;
  text-align: center;
  margin: 0;
}
.react-datepicker__day {
  border: 1px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  color: #222;
  cursor: pointer;
  font-weight: 700;
  font-size: 17px;
  outline: none;
}
.react-datepicker__day:hover {
  background-color: #f2effc;
  box-sizing: border-box;
  color: #46367a;
}
.react-datepicker__day--today {
  color: #7c60d9;
}
.react-datepicker__day--outside-month {
  color: #a6aeb2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-datepicker__day--disabled {
  color: #a6aeb2;
}
.react-datepicker__day--disabled.react-datepicker__day--outside-month {
  font-weight: 300;
}
.react-datepicker__day--highlighted {
  position: relative;
}
.react-datepicker__day--highlighted::after {
  background-color: #39a4a1;
  border-radius: 3px;
  content: "";
  display: block;
  height: 6px;
  left: 16px;
  position: absolute;
  bottom: 3px;
  width: 6px;
}
.react-datepicker__day--highlighted:hover::after {
  background-color: #6dcc6a;
}
.react-datepicker__day--highlighted.react-datepicker__day--selected::after {
  background-color: #eaeaea;
}
.react-datepicker__day--highlighted.react-datepicker__day--selected:hover::after {
  background-color: #eaeaea;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #7c60d9;
  color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #7c60d9;
  color: #fff;
}
.react-datepicker__day--keyboard-selected {
  background-color: #7c60d9;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #ebf5eb;
}
.react-datepicker__day--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #a6aeb2;
}
.react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #a6aeb2;
}
.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__input-time-container {
  padding: 10px 20px;
  text-align: center;
}

.react-datepicker-time__caption {
  display: inline-block;
  margin-right: 10px;
}

.react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker-time__input {
  font-family: "Overpass";
}

.react-datepicker__time-container {
  border-left: 1px solid #e6e9eb;
  position: relative;
  overflow: hidden;
}

.react-datepicker__time {
  overflow: scroll;
  font-size: 17px;
  line-height: 22px;
  cursor: pointer;
}

.react-datepicker__time-list-item {
  padding: 17px 20px;
  box-sizing: border-box;
  text-align: center;
}

.react-datepicker__time-list-item:hover {
  background-color: #f2effc;
  color: #46367a;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__time-list-item--selected:hover {
  background-color: #7c60d9;
  color: #fff;
}

.react-datepicker__header--time {
  height: 59px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.react-datepicker-time__header {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .react-datepicker__day {
    width: 40px;
    height: 40px;
    font-size: 13px;
    line-height: 16px;
  }
  .react-datepicker__day-name {
    font-size: 10px;
    line-height: 16px;
    width: 40px;
  }
  .react-datepicker__header--time {
    border-bottom: 1px solid #aeaeae;
    height: 56px;
  }
  .react-datepicker-time__header {
    font-size: 17px;
    line-height: 22px;
  }
  .react-datepicker__time {
    font-size: 13px;
    line-height: 16px;
  }
  .react-datepicker__time-list-item {
    padding: 12px 20px;
    box-sizing: border-box;
    text-align: center;
  }
}
